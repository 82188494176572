import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { touch } from "redux-form";
import _ from "underscore";

import type { MenuSelectProps } from "@hexocean/braintrust-ui-components";
import { MenuSelect } from "@hexocean/braintrust-ui-components";

type MenuSelectFieldProps = Omit<MenuSelectProps, "value" | "onChange"> &
  TypedWrappedFieldProps<any>;

const ReduxFormMenuSelectFieldFactory =
  (Component: React.FC<React.PropsWithChildren<MenuSelectProps>>) =>
  ({
    input,
    meta,
    options,
    multiple,
    preventUnselect,
    ...props
  }: MenuSelectFieldProps) => {
    // only touch event is supported for now

    // to handle "dirty" state properly
    const isEqualToInitial = (value) => {
      const noInitial = !meta.initial || !meta.initial.length;
      const valueNotSelected = !value || !value.length;

      if (noInitial && valueNotSelected) return true;

      return _.isEqual(value, meta.initial);
    };

    return (
      <Component
        {...props}
        value={input.value}
        onChange={(value) => {
          meta.dispatch(touch(meta.form, input.name));
          input.onChange(isEqualToInitial(value) ? meta.initial : value);
        }}
        options={options}
        preventUnselect={preventUnselect}
        multiple={multiple}
      />
    );
  };

export const MenuSelectField = ReduxFormMenuSelectFieldFactory(MenuSelect);
