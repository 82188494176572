import React from "react";
import { useSearchParams } from "react-router-dom";
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from "redux-form";
import { Field } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";
import {
  BriefcaseIcon,
  CircleCheckGreenIcon,
  CircleUncheckedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { JobTypeForm } from "@js/apps/common/components/filters/config";
import { StyledPopoverForm } from "@js/apps/common/components/filters/fields/styled-popover-form.styles";
import { useUser } from "@js/apps/common/hooks";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { getJobTypeLabels } from "@js/apps/jobs/utils";
import { ButtonSelectField } from "@js/forms/fields";
import { PossibleFilters } from "@js/types/common";

import { JobPopoverFilterButton } from "../../components";

const formId = "job-filters__job-type";

export const JobTypeFilter = () => {
  const [params] = useSearchParams();
  const selectedJobType = params.get("job_type");
  const isAnyOptionSelected = !!selectedJobType;
  const user = useUser();
  const { freelance } = getJobTypeLabels(user?.account_type);
  const { handleFilterApplied } = useHandleFilterApplied();

  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.JOB_TYPE,
      filter_value: values,
    });
  };

  const labelsForEachOptions = [
    selectedJobType?.includes(ENUMS.JobType.FREELANCE) && freelance,
    selectedJobType?.includes(ENUMS.JobType.DIRECT_HIRE) &&
      ENUMS.JobTypeLabels.direct_hire,
    selectedJobType?.includes(ENUMS.JobType.GRANT) && ENUMS.JobTypeLabels.grant,
  ].filter(Boolean);

  const label = isAnyOptionSelected
    ? labelsForEachOptions.join(", ")
    : "Job type";

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <JobPopoverFilterButton
      startIcon={<BriefcaseIcon />}
      isActive={isAnyOptionSelected}
      label={label}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      popoverContent={
        <JobTypeForm
          form={formId}
          onSubmitSuccess={() => {
            setIsOpen(false);
          }}
          onSubmitSideAction={handleSubmitSideAction}
        >
          {({ submit }) => {
            return (
              <Field
                submit={submit}
                name="job_type"
                component={JobTypeFilterContent}
              />
            );
          }}
        </JobTypeForm>
      }
    />
  );
};

type JobTypeFilterContentProps = {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  submit: () => void;
};

export const JobTypeFilterContent = ({
  submit,
  input,
  meta,
}: JobTypeFilterContentProps) => {
  const jobTypeField = { input, meta };
  const handleReset = () => {
    jobTypeField.input.onChange(null);
  };
  const user = useUser();
  const { freelance } = getJobTypeLabels(user?.account_type);

  const JOB_TYPES_OPTIONS = [
    {
      label: freelance,
      value: ENUMS.JobType.FREELANCE,
    },
    {
      label: ENUMS.JobTypeLabels.direct_hire,
      value: ENUMS.JobType.DIRECT_HIRE,
    },
    {
      label: ENUMS.JobTypeLabels.grant,
      value: ENUMS.JobType.GRANT,
    },
  ];

  return (
    <JobPopoverFilterButton.Content onApply={submit} onReset={handleReset}>
      <StyledPopoverForm>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <ButtonSelectField
            className="job-type-filter-button"
            labelledBy="sub-category-label"
            variant="transparent-border-beige"
            selectedVariant="violet"
            labelVariant="primary"
            typographySize="large"
            startIcon={<CircleUncheckedIcon />}
            selectedIcon={<CircleCheckGreenIcon />}
            shape="squared"
            multiple
            options={JOB_TYPES_OPTIONS}
            input={jobTypeField.input}
            meta={jobTypeField.meta}
          />
        </Box>
      </StyledPopoverForm>
    </JobPopoverFilterButton.Content>
  );
};
