// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export const assertUnreachable = (_arg: never): never | void => {
  if (process.env.DEPLOYMENT !== "production") {
    throw new Error(`Unreachable code (env: ${process.env.DEPLOYMENT})`);
  }

  console.error(new Error("Unreachable code"));
};

// @param - generic type guard - https://www.typescriptlang.org/docs/handbook/advanced-types.html
export const typeGuard = <DT, T extends DT>(
  data: DT,
  field: string,
): data is T => {
  return (data as T)[field] !== undefined;
};

export function isNotNullable<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null;
}

//https://www.totaltypescript.com/iterate-over-object-keys-in-typescript
export function isAKeyOfAnObject<SomeObject extends object>(
  someObject: SomeObject,
  someKey: PropertyKey,
): someKey is keyof SomeObject {
  return someKey in someObject;
}
// https://www.totaltypescript.com/tips/create-your-own-objectkeys-function-using-generics-and-the-keyof-operator
export function typedObjectKeys<Obj extends Record<string, unknown>>(
  obj: Obj,
): (keyof Obj)[] {
  return Object.keys(obj) as (keyof Obj)[];
}
