import { useCallback, useMemo } from "react";

import type {
  JobFiltersFetchParams,
  TalentFiltersFetchParams,
} from "@js/apps/common/components/filters";
import { type Location } from "@js/apps/common/components/filters/config";
import { useAccountType } from "@js/apps/common/hooks";
import type { FilterLocation } from "@js/apps/filters/types";
import { TALENT_LOCATION } from "@js/apps/freelancer/constants";
import { useGetPostCategoriesQuery } from "@js/apps/give-and-get-help/api";
import { createApprovalStatusString } from "@js/apps/jobs/apps/listing/utils/create-approval-status-string";
import { createHelpCategoryString } from "@js/apps/jobs/apps/listing/utils/create-help-category-string";
import { createRateString } from "@js/apps/jobs/apps/listing/utils/create-rate-string";
import { createSkillsString } from "@js/apps/jobs/apps/listing/utils/create-skills-string";
import { createYearsOfExperienceString } from "@js/apps/jobs/apps/listing/utils/create-years-of-experience-string";
import { JOB_LOCATION } from "@js/apps/jobs/context";
import { useGetRoleName } from "@js/apps/roles/hooks";
import type { Skill } from "@js/types/admin";
import { assertUnreachable } from "@js/utils";

const WORK_FROM_ANYWHERE_LABEL = "Work from anywhere";

type CreateStringDependsOnSelectedFilters = (
  filters: Partial<JobFiltersFetchParams | TalentFiltersFetchParams>,
  filterLocation: FilterLocation,
  filterSkillsData: Skill[],
) => string;
type CreateCommitment = (
  availabilityFrom: number | undefined,
  availabilityTo: number | undefined,
) => string | null;

const createCommitmentString: CreateCommitment = (
  availabilityFrom,
  availabilityTo,
) => {
  if (!availabilityFrom || !availabilityTo) return null;
  if (availabilityFrom === availabilityTo) return `${availabilityFrom} hrs/wk`;
  return `${availabilityFrom}-${availabilityTo} hrs/wk`;
};

const createJobTypeString = (jobType: string | undefined) => {
  if (typeof jobType !== "string") return null;
  return jobType
    .split(",")
    .map(
      (item) => ENUMS.JobTypeLabels[item as keyof typeof ENUMS.JobTypeLabels],
    )
    .join(", ");
};

const createLocationString = (filters: Location) => {
  const { work_from_anywhere, custom_location, city, country_code } = filters;
  const custom_locations = custom_location?.split(",");
  const isCustomLocationSelected =
    custom_locations &&
    custom_locations.every(
      (location) => location in ENUMS.JobCustomLocationTypeLabels,
    );
  const isMyLocationSelected = city && country_code;
  const myLocation = `${city}, ${country_code}`;

  if (work_from_anywhere) {
    if (isCustomLocationSelected) {
      return `${WORK_FROM_ANYWHERE_LABEL}, ${custom_locations
        .map(
          (location) =>
            ENUMS.JobCustomLocationTypeLabels[
              location as keyof typeof ENUMS.JobCustomLocationTypeLabels
            ],
        )
        .join(", ")}`;
    }

    if (isMyLocationSelected) {
      return `${WORK_FROM_ANYWHERE_LABEL}, ${myLocation}`;
    }

    return WORK_FROM_ANYWHERE_LABEL;
  }

  if (isCustomLocationSelected) {
    return custom_locations
      .map(
        (location) =>
          ENUMS.JobCustomLocationTypeLabels[
            location as keyof typeof ENUMS.JobCustomLocationTypeLabels
          ],
      )
      .join(", ");
  }

  if (isMyLocationSelected) {
    return myLocation;
  }

  return null;
};

export const useCreateSkillsLabelsString = (skillsObjectsArray: Skill[]) => {
  const skillsLabelsString = useMemo(
    () => createSkillsString(skillsObjectsArray),
    [skillsObjectsArray],
  );

  if (!skillsObjectsArray) {
    return null;
  }

  return skillsLabelsString;
};

const useCreateHelpCategoryString = (id?: number) => {
  const { isEmployer } = useAccountType();
  const { data } = useGetPostCategoriesQuery(undefined, {
    skip: !id || isEmployer,
  });

  return createHelpCategoryString(id, data);
};

const createAppliedRecentlyString = (
  appliedRecently?: boolean,
): string | null =>
  typeof appliedRecently === "boolean"
    ? appliedRecently
      ? "Applied to a job in the last 30 days"
      : null
    : null;

const createLookingForWorkString = (
  lookingForWorkOptionString?: string,
): string | null =>
  !!lookingForWorkOptionString
    ? lookingForWorkOptionString
        .split(",")
        .map(
          (option) =>
            ENUMS.FreelancerAvailabilityForWorkLabels[
              option as keyof typeof ENUMS.FreelancerAvailabilityForWorkLabels
            ],
        )
        .join(", ")
    : null;

export const useCreateStringDependsOnSelectedFilters: CreateStringDependsOnSelectedFilters =
  (filters, filterLocation, filterSkillsData) => {
    const extractFilterValue = useCallback(
      (filterKey: string) => {
        return filterKey in filters
          ? filters[filterKey as keyof typeof filters]
          : undefined;
      },
      [filters],
    );

    const roleFilter = filters.role;
    const parsedRoleFilter =
      !!roleFilter && !isNaN(Number(roleFilter))
        ? Number(roleFilter)
        : undefined;
    const roleLabelString = useGetRoleName(parsedRoleFilter);
    const skillsLabelsString = useCreateSkillsLabelsString(filterSkillsData);
    const helpCategoryLabelString = useCreateHelpCategoryString(
      extractFilterValue("help_category") as number,
    );

    const filtersStringArray = useMemo(() => {
      const location = createLocationString(filters);
      const commitment = createCommitmentString(
        extractFilterValue("availability_from") as number,
        extractFilterValue("availability_to") as number,
      );
      const rate = createRateString(filters.hourly_budget_minimum_usd);
      const jobType = createJobTypeString(
        extractFilterValue("job_type") as string,
      );
      const approvalStatus = createApprovalStatusString(filters.approved);
      const appliedRecently = createAppliedRecentlyString(
        extractFilterValue("applied_recently") as boolean,
      );
      const lookingForWork = createLookingForWorkString(
        extractFilterValue("looking_for_work") as string,
      );
      const experience = createYearsOfExperienceString(
        extractFilterValue("experience") as string,
      );

      switch (filterLocation) {
        case JOB_LOCATION.job_listing:
        case JOB_LOCATION.job_search:
        case JOB_LOCATION.legacy_job_search:
        case JOB_LOCATION.jobs_landing_page: {
          return [
            roleLabelString,
            skillsLabelsString,
            location,
            commitment,
            rate,
            jobType,
          ];
        }
        case TALENT_LOCATION.talent_listing:
        case TALENT_LOCATION.talent_search:
        case TALENT_LOCATION.legacy_talent_search: {
          return [
            roleLabelString,
            skillsLabelsString,
            location,
            approvalStatus,
            experience,
            lookingForWork,
            appliedRecently,
            helpCategoryLabelString,
          ];
        }
        case JOB_LOCATION.saved_jobs:
        case JOB_LOCATION.employer_profile:
        case JOB_LOCATION.employer_own_jobs:
        case JOB_LOCATION.talent_home:
        case JOB_LOCATION.job_details:
        case JOB_LOCATION.public_job_details:
        case JOB_LOCATION.recommended_jobs_modal:
        case JOB_LOCATION.public_job_listing:
        case JOB_LOCATION.talent_invitations: {
          return [];
        }
        default: {
          assertUnreachable(filterLocation);
          return filterLocation;
        }
      }
    }, [
      filters,
      extractFilterValue,
      roleLabelString,
      skillsLabelsString,
      filterLocation,
      helpCategoryLabelString,
    ]);

    return filtersStringArray.filter(Boolean).join(", ");
  };
