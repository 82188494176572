import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

type OfferNotFoundProps = { reason: EnumType<typeof ENUMS.OfferStatus> };

export const OfferNotFound = ({ reason }: OfferNotFoundProps) => {
  let message = "";

  if (reason === ENUMS.OfferStatus.CANCELLED)
    // eslint-disable-next-line max-len
    message =
      "This offer is no longer available because either client decided to cancel his offer or talent canceled their bid.";
  else message = "Sorry, we can't find this offer.";

  return (
    <>
      <Typography component="h1" className="not-found-page-title">
        Uh oh!
      </Typography>
      <Typography
        component="p"
        variant="title"
        fontWeight={400}
        className="not-found-page-message"
      >
        {message}{" "}
      </Typography>
      <Typography
        component="p"
        variant="title"
        size="medium"
        fontWeight={400}
        className="not-found-page-info not-found-page-info--no-max-width"
      >
        <Typography component="p">
          Please reach out to us with any questions.
        </Typography>
        <Typography component="p" mt={1}>
          Return to the <RouterLink to="/">homepage</RouterLink>
        </Typography>
      </Typography>
    </>
  );
};
