import { useMatch, useNavigate } from "react-router-dom";

import { Button } from "@hexocean/braintrust-ui-components";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { fetchEmployerProfile } from "@js/apps/employer/actions";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import { assertUnreachable } from "@js/utils";

import { useCancelJobOffer } from "../../hooks/cancel-job-offer";

import { MessageButton } from "./message-button";

type EmployerOfferCTAButtonsProps = {
  jobId: number;
  offerId?: number;
  offerStatus: EnumType<typeof ENUMS.OfferStatus> | null;
  previewPage?: boolean;
  interlocutor: number;
};

export const EmployerOfferCTAButtons = ({
  jobId,
  offerId,
  offerStatus,
  previewPage,
  interlocutor,
}: EmployerOfferCTAButtonsProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const viewOfferLink = `/jobs/${jobId}/offers/${offerId}`;
  const match = useMatch(viewOfferLink);
  const isNodeStaff = useIsNodeStaff();

  const cancelOffer = useCancelJobOffer({
    // TODO: look into useCreateOrEditOfferMeta, it returns offerId as number | udnefined
    // yet offerId is required down the line (in EmployerNavigation --> EmployerOfferCTAButtons) and in cancelOffer mutation (returned from useCancelEmployerOfferMutation)
    offerId: offerId as number,
    onSuccess: () => {
      Snackbar.success("Offer successfully cancelled.");

      if (!isNodeStaff) {
        dispatch(fetchEmployerProfile());
      }

      if (!match) navigate(viewOfferLink);
    },
  });

  switch (offerStatus) {
    case null:
      return (
        <Button variant="positive" shape="squared" size="medium" type="submit">
          Review offer
        </Button>
      );

    case ENUMS.OfferStatus.ACCEPTED:
    case ENUMS.OfferStatus.CANCELLED:
    case ENUMS.OfferStatus.REJECTED:
    case ENUMS.OfferStatus.EXPIRED:
      return <MessageButton interlocutor={interlocutor} />;

    case ENUMS.OfferStatus.NEW:
    case ENUMS.OfferStatus.UPDATED:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT_ACH_CONFIRMATION:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_FAILED:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_IN_PROGRESS:
      return (
        <>
          <Button
            variant="destructive-outlined"
            shape="squared"
            size="medium"
            onClick={cancelOffer}
          >
            Cancel offer
          </Button>
          {previewPage && offerId ? (
            <Button
              variant="primary"
              sx={{ padding: "12px 24px !important" }}
              shape="squared"
              type="submit"
              size="medium"
              to={`/jobs/${jobId}/offers/${offerId}/edit`}
              RouterLink={RouterLink}
            >
              Edit offer
            </Button>
          ) : (
            <Button
              variant="positive"
              shape="squared"
              size="medium"
              type="submit"
            >
              Review offer
            </Button>
          )}
          <MessageButton interlocutor={interlocutor} />
        </>
      );

    default:
      assertUnreachable(offerStatus);
      return null;
  }
};
