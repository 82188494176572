import { useState } from "react";
import type { WrappedFieldProps } from "redux-form";

import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import { mapFullAddressComponentsToAddress } from "@js/forms/fields";
import { isError } from "@js/forms/utils";

type UseJobLocationFilterFieldManagerProps = {
  work_from_anywhere: WrappedFieldProps;
  work_from_location: WrappedFieldProps;
  location: WrappedFieldProps; // just for input string purpose
  custom_location: WrappedFieldProps; // custom option like United states only, Europe etc
  city: WrappedFieldProps;
  state: WrappedFieldProps;
  country_code: WrappedFieldProps;
  session_token: WrappedFieldProps;
};

export const useJobLocationFilterFieldManager = ({
  work_from_anywhere,
  work_from_location,
  location,
  custom_location,
  city,
  state,
  country_code,
  session_token,
}: UseJobLocationFilterFieldManagerProps) => {
  const [locationReady, setLocationReady] = useState(true);

  const isWorkFromAnywhereSelected = work_from_anywhere.input.value;
  const isWorkFromLocationSelected =
    work_from_location.input.value && !!location.input.value;

  const handleReset = () => {
    work_from_anywhere.input.onChange(null);
    work_from_location.input.onChange(null);
    location.input.onChange(null);
    custom_location.input.onChange(null);
    city.input.onChange(null);
    state.input.onChange(null);
    country_code.input.onChange(null);
    session_token.input.onChange(null);
  };

  const error = isError([
    work_from_anywhere,
    work_from_location,
    location, // just for input string purpose
    custom_location, // custom option like United states only, Europe etc
    city,
    state,
    country_code,
    session_token,
  ]);

  const onChange = (value: TalentMixedLocation) => {
    setLocationReady(true);
    if (value.custom) {
      location.input.onChange(value.name);
      custom_location.input.onChange(value.id);
      city.input.onChange(null);
      state.input.onChange(null);
      country_code.input.onChange(null);
      session_token.input.onChange(null);
    } else {
      const fullAddress = mapFullAddressComponentsToAddress(
        value.address_components,
      );
      location.input.onChange(value.formatted_address);
      custom_location.input.onChange(null);
      city.input.onChange(fullAddress.city?.short_name || null);
      state.input.onChange(fullAddress.state?.short_name || null);
      country_code.input.onChange(fullAddress.country?.short_name || null);
      session_token.input.onChange(value.session_token || null);
    }
    work_from_location.input.onChange(true);
  };

  return {
    handleReset,
    onChange,
    error,
    disableApply: error || !locationReady,
    options: {
      isWorkFromAnywhereSelected,
      isWorkFromLocationSelected,
    },
  };
};
