import React from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";

type SavedSearchesEmptyStateProps = {
  closeDrawer: () => void;
};

export const SavedSearchesEmptyState = ({
  closeDrawer,
}: SavedSearchesEmptyStateProps) => {
  return (
    <Box maxWidth={500} textAlign="center" margin="auto">
      <img
        src={`${SETTINGS.STATIC_URL}jobs-filter/empty-state-saved-searches.svg`}
        alt=""
      />
      <Typography
        component="h2"
        variant="paragraph"
        fontWeight={500}
        size="medium"
      >
        Save your searches for later!
      </Typography>
      <Typography mb={2} component="p" variant="paragraph" size="medium">
        Save a search for easy access next time, plus email notifications when
        new jobs match your criteria.
      </Typography>
      <Button variant="secondary" size="x-small" onClick={closeDrawer}>
        Back to Job Search
      </Button>
    </Box>
  );
};
